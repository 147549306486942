import { Chip, IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Iconify from '../iconify/Iconify';
import TableList from '../table-list/TableList';
import { listaCompromisos } from '../../helper/coleccionCreditos';
import { cargarCreditoAEditar } from '../../store';
import { MAIN_PATH, PATH_NAME } from '../../constants';

export const Compromisos = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { compromisos, loading } = useSelector((state) => state?.creditosActivos?.compromisosRealizados);
  const { creditos } = useSelector((state) => state?.creditosActivos);

  const data = listaCompromisos(compromisos);

  const redireccionarEdicion = (idCredito) => {
    const credito = creditos.filter((credito) => credito.id === idCredito)[0];
    if (credito === undefined) {
      return;
    }
    dispatch(cargarCreditoAEditar(credito));
    navigate(`/admin/${MAIN_PATH}/${PATH_NAME.gestionar_credito_vencido}`, { replace: true });
  };

  const columnas = [
    {
      label: 'N. Crédito',
      name: 'creditoId',
      options: {
        filter: true,
      },
    },
    {
      label: 'Monto Compromiso',
      name: 'monto',
      options: {
        filter: true,
      },
    },
    {
      label: 'Fecha del Compromiso',
      name: 'fecha_Compromiso',
      options: {
        filter: true,
      },
    },
    {
      label: 'Observaciones',
      name: 'nota_Compomiso',
      options: {
        filter: false,
      },
    },
    {
      label: 'Estado',
      name: 'estado',
      options: {
        filter: false,
        customBodyRender: (value) => (
          <Chip label={value ? 'Vencido' : 'Pendiente'} color={value ? 'error' : 'default'} />
        ),
      },
    },
    {
      name: 'Gestionar',
      options: {
        sort: false,
        customBodyRender: (_value, tableMeta) => (
          <Tooltip title="Gestionar" aria-label="Gestionar">
            <IconButton aria-label="Gestionar" onClick={() => redireccionarEdicion(tableMeta.rowData[0])}>
              <Iconify icon="material-symbols:edit-square-outline" />
            </IconButton>
          </Tooltip>
        ),
      },
    },
  ];

  return <TableList titulo="Lista de Compromisos Atrasados" columnas={columnas} loading={loading} data={data} />;
};
