import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Chip from '@mui/material/Chip';
import { IconButton, Tooltip } from '@mui/material';
import { useState, useCallback } from 'react';
import TableListPagination from '../components/table-list/TableListPagination';
import { MAIN_PATH, PATH_NAME, TITLES } from '../constants';
import { coleccionCreditosActivos } from '../helper/coleccionCreditos';
import Iconify from '../components/iconify/Iconify';
import { cargarCreditoAEditar, startConsultarCreditosActivos } from '../store';

export function GestorCreditos() {
 const location = useLocation();
 const isGestorCreditos = location?.pathname.includes(PATH_NAME.gestor_de_creditos);
 const [searchTerm, setSearchTerm] = useState('');
 const [searchTimeout, setSearchTimeout] = useState(null);

 const { loading, creditos, totalCount, currentPage } = useSelector((state) => state.creditosActivos);
 const data = coleccionCreditosActivos(creditos);
 const navigate = useNavigate();
 const dispatch = useDispatch();

 const handlePageChange = (newPage) => {
   dispatch(startConsultarCreditosActivos(newPage, searchTerm));
 };

 const handleSearch = useCallback((searchText) => {
   if (searchTimeout) clearTimeout(searchTimeout);
   
   const timeoutId = setTimeout(() => {
     setSearchTerm(searchText);
     dispatch(startConsultarCreditosActivos(1, searchText));
   }, 300);
   
   setSearchTimeout(timeoutId);
 }, [dispatch]);

 const redireccionarEdicion = (idCredito) => {
   const credito = creditos.find((c) => c.id === idCredito);
   dispatch(cargarCreditoAEditar(credito));
   navigate(`/admin/${MAIN_PATH}/${PATH_NAME.gestionar_credito}`, { replace: true });
 };

 const getColor = (value) => {
   switch(value) {
     case 'Desembolsado': return 'success';
     case 'En Estudio': return 'primary';
     case 'Pendiente Firma': return 'warning';
     case 'Pendiente Desembolso': 
     case 'cancelado': return 'error';
     case 'Pendiente por Aprobar': return 'secondary';
     default: return 'default';
   }
 };

 const columnas = [
   {
     label: 'Nombre',
     name: 'nombre',
     options: { filter: false,        sort: false, }
   },
   {
     label: 'Cedula',
     name: 'cedula',
     options: { filter: false,         sort: false, }
   },
   {
     label: 'N. Solicitud',
     name: 'id',
     options: { filter: false,        sort: false, }
   },
   {
     label: 'Monto Aprobado',
     name: 'valorSolicitado',
     options: { filter: false,        sort: false, }
   },
   {
     label: 'Estado',
     name: 'estado',
     options: {
       filter: false,
       sort: false,
       customBodyRender: (value) => (
         <Chip
           label={value}
           color={getColor(value)}
           variant={['Desembolsado', 'Cancelado', 'Pendiente Desembolso'].includes(value) ? 'filled' : 'outlined'}
         />
       )
     }
   },
   {
     name: 'Gestionar',
     options: {
       sort: false,
       filter: false,
       customBodyRender: (_value, tableMeta) => (
         <Tooltip title="Gestionar">
           <IconButton onClick={() => redireccionarEdicion(tableMeta?.rowData?.[2])}>
             <Iconify icon="material-symbols:edit-square-outline" />
           </IconButton>
         </Tooltip>
       )
     }
   },
   {
     name: ' ',
     options: {
       sort: false,
       customBodyRender: (_value, tableMeta) => (
         <Tooltip title="Copiar y Pegar">
           <IconButton onClick={() => navigator.clipboard.writeText(tableMeta.rowData[1])}>
             <Iconify icon="mdi-light:clipboard-text" />
           </IconButton>
         </Tooltip>
       )
     }
   }
 ];

 return (
   <>
     <Helmet>
       <title>{isGestorCreditos ? TITLES.gestor_de_creditos : TITLES.default}</title>
     </Helmet>
     
     <TableListPagination 
       titulo="Lista de Créditos Activos" 
       columnas={columnas} 
       data={data} 
       loading={loading}
       onPageChange={handlePageChange}
       totalCount={totalCount}
       currentPage={currentPage}
       onSearch={handleSearch}
     />
   </>
 );
}